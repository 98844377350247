export const isObject = (value: any) => {
  return value && typeof value === 'object' && !Array.isArray(value);
};

/**
 * @summary Преобразует все данные в snake_case формат (рекурсивно).
 *
 * @param data - Объект, который нужно трансформировать в формат snake_case.
 */
export const toSnakeCaseFormat = (data: any): any => {
  if (data instanceof FormData) return data;
  const toSnakeCase = (text: any) =>
    text.replace(/([A-Z])/g, '_$1').toLowerCase();

  if (Array.isArray(data)) {
    return data.map((item) => toSnakeCaseFormat(item));
  }

  if (isObject(data)) {
    return Object.keys(data).reduce((prev, key) => {
      let value = data[key];
      if (value instanceof File) {
        return { ...prev, [toSnakeCase(key)]: value };
      }
      if (isObject(value) || Array.isArray(value)) {
        value = toSnakeCaseFormat(value);
      }

      return { ...prev, [toSnakeCase(key)]: value };
    }, {});
  }
  return data;
};

/**
 * @summary Преобразует все данные в camelCase формат (рекурсивно).
 *
 * @param data - Объект, который нужно трансформировать в формат CamelCase.
 */
export const toCamelCaseFormat = (data: any): any => {
  const replacer = ([, symbol]: any) => symbol.toUpperCase();
  const toCamelCase = (text: any) => text.replace(/_([a-z0-9])/g, replacer);

  if (Array.isArray(data)) {
    return data.map((item: any) => toCamelCaseFormat(item));
  }

  if (isObject(data)) {
    return Object.keys(data).reduce((prev, key) => {
      let value = data[key];

      if (isObject(value) || Array.isArray(value)) {
        value = toCamelCaseFormat(value);
      }

      return { ...prev, [toCamelCase(key)]: value };
    }, {});
  }

  return data;
};

import { Button } from '@ui';

export const NotFound = () => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-2 bg-white">
      <img src="/assets/not-found2.gif" alt="404" />
      <Button
        className="w-[20%] justify-center sm:w-[80%]"
        onClick={() => (window.location.href = '/')}
      >
        Вернутся домой
      </Button>
    </div>
  );
};

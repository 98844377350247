import { useQuery } from '@tanstack/react-query';
import { instance } from '@configs';

import { IResponse } from '@types';

import { ConditionsQueryKeys, EndpointsConditionsQueryKeys } from './constants';
import { IConditionResponse } from './types';

export const useGetConditionsQuery = (id: string) => {
  return useQuery({
    queryFn: async (): Promise<IConditionResponse> => {
      const { data } = await instance.post<IResponse<IConditionResponse>>(
        EndpointsConditionsQueryKeys.TokenizationConditions,
        { id }
      );
      return data.payload;
    },
    queryKey: [ConditionsQueryKeys.TokenizationConditions],
  });
};

import { AlifPayBlackIcon } from '@icons';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="bg-black text-white">
      <div className="container">
        <div className="flex items-center justify-between border-black/10 py-10 sm:flex-col sm:items-start sm:gap-5 sm:border-b sm:py-7">
          <Link to="/">
            <AlifPayBlackIcon />
          </Link>
          <div className="flex flex-col gap-1 sm:gap-0.5">
            <a href="tel:900" className="text-[22px] font-normal">
              900
            </a>
            <p className="text-sm text-grey-500">
              Для звонков с мобильных в Таджикистане
            </p>
          </div>
          <div className="flex flex-col gap-1 sm:gap-0.5">
            <a href="tel:+992 48 888 1111" className="text-[22px] font-normal">
              +992 48 888 1111
            </a>
            <p className="text-sm text-grey-500">
              Для звонков из любой точки мира
            </p>
          </div>
          <div className="flex flex-col gap-1 sm:gap-0.5">
            <a href="tel:+992 44 600 1520" className="text-[22px] font-normal">
              +992 44 600 1520
            </a>
            <p className="text-grey-500">Телефон доверия НБТ</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

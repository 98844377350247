'use client';

import { LegacyRef, MutableRefObject, useEffect, useState } from 'react';

import { useClickAway } from '@uidotdev/usehooks';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { IoMdArrowDropdown } from 'react-icons/io';

import { IOption } from '@types';

import { IAutocompleteProps } from './types';

import { Input } from '../input';

export const Autocomplete = ({
  onChange,
  placeholder,
  options,
  onSelect,
  isError,
  errorText,
  defaultOption,
  isFilter = true,
  label = '',
}: IAutocompleteProps) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterOptions, setFilterOptions] = useState<IOption[]>(options || []);
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleOpenMenu = (): void => setOpenMenu(!openMenu);

  const ref: MutableRefObject<Element> = useClickAway(() => {
    handleOpenMenu();
  });

  const handleFilterOptions = (value: string): void => {
    if (!isFilter) {
      return;
    }

    setOpenMenu(true);

    const filteredOptions: IOption[] = options.filter((option: IOption) =>
      option.label?.toLowerCase().includes(value.toLowerCase())
    );

    if (!value) {
      setSelectedOption('');
    }

    if (onChange) {
      onChange(value);
    }
    setFilterOptions(filteredOptions);
    setSearchValue(value);
  };

  const handleSelectOption = (newOption: IOption): void => {
    const { value, label } = newOption;

    const hasOption: boolean = String(selectedOption) === value;

    if (hasOption) {
      setSelectedOption('');
      setSearchValue('');
      return;
    }

    setSearchValue(label);
    setSelectedOption(String(value));
    setFilterOptions(options);
    onSelect(newOption);
    handleOpenMenu();
  };

  useEffect(() => {
    const value = String(defaultOption?.value || '');
    const label = String(defaultOption?.label || '');

    setSelectedOption(value);
    setSearchValue(label);
  }, [defaultOption]);

  useEffect(() => {
    setFilterOptions(options);
  }, [options.length]);

  return (
    <div className="relative">
      <div onClick={handleOpenMenu}>
        <Input
          label={label}
          isError={isError}
          errorText={errorText}
          initialValue={searchValue}
          placeholder={placeholder}
          classNameEndIcon="!top-3.5"
          className="!w-full text-start"
          endIcon={
            <IoMdArrowDropdown
              onClick={handleOpenMenu}
              className={clsx(
                'cursor-pointer text-2xl text-black/50 transition-transform duration-200',
                [openMenu && 'rotate-[180deg]']
              )}
            />
          }
          onChange={(value) => handleFilterOptions(String(value))}
        />
      </div>
      <AnimatePresence>
        {openMenu && (
          <motion.div
            exit={{ y: -5, opacity: 0 }}
            initial={{ y: -5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            ref={ref as LegacyRef<HTMLDivElement> | undefined}
            className="perfect-scroll absolute z-50 max-h-[230px] w-full overflow-y-auto rounded-lg bg-white shadow"
          >
            {filterOptions.map((option) => (
              <div
                key={option.label}
                onClick={() => handleSelectOption(option)}
                className={clsx(
                  'cursor-pointer px-5 py-4 text-sm text-grey-500 transition-all duration-200 hover:bg-grey-30 sm:p-2.5',
                  [selectedOption === option.value && 'bg-grey-30']
                )}
              >
                {option.value}
              </div>
            ))}
            {filterOptions.length === 0 && (
              <p className="px-5 py-4 text-sm sm:p-2.5">Хичи нест ку...</p>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

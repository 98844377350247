export enum TransactionTypes {
  Wallet = 'wallet',
  KortiMilli = 'card',
  Salom = 'installment',
  Operator = 'mobile',
}

export enum TransactionTypesLabel {
  Wallet = 'Алиф Моби',
  KortiMilli = 'Картой',
  Salom = 'Салом',
  Operator = 'Баланс телефона',
  NumberOperator = 'Моб. оператор',
  TokenizationTitleCard = 'Карта',
}

export enum TransactionGroupTypes {
  Card = 'card',
  Operator = 'mobile',
  Wallet = 'wallet',
  Salom = 'installment',
}

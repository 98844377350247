export const Offerta = ({ isTokenization }: { isTokenization?: boolean }) => {
  const check: string = isTokenization
    ? 'на указанном счёте'
    : 'на указанной карте';

  return (
    <div className="text-base text-grey-500">
      Совершая оплату/перевод вы принимаете условия{' '}
      <a target="_blank" href="/doc/public_offer.pdf" className="text-primary">
        Публичной оферты
      </a>{' '}
      и{' '}
      <a target="_blank" href="/doc/tarify_KM.pdf" className="text-primary">
        Тарифов,
      </a>{' '}
      и тем самым соглашаетесь, что осуществляете оплату/перевод с остатка
      средств {check} и даете Распоряжение о переводе их Получателю
    </div>
  );
};

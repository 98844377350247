import { SVGProps } from 'react';

export const AlifPayIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="121"
      height="36"
      viewBox="0 0 121 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.5473 6.49275H52.5856V23.9591H55.5473V6.49275ZM59.6481 10.062C60.7112 10.062 61.5466 9.30256 61.5466 8.16345C61.5466 7.10028 60.7872 6.26493 59.6481 6.26493C58.5849 6.26493 57.6736 7.02434 57.6736 8.16345C57.7496 9.22662 58.5849 10.062 59.6481 10.062ZM61.1669 11.5048H58.1293V23.9591H61.1669V11.5048ZM70.1279 8.69503C70.6595 8.69503 71.3429 8.84691 72.1023 9.22662L73.0136 6.87246C71.8745 6.34087 70.8114 6.03711 69.6722 6.03711C66.7106 6.03711 64.7361 7.78374 64.7361 10.8973V11.5048H62.7616V14.0109H64.7361V23.9591H67.6978V14.0109H71.5708V11.5048H67.6978V10.9733C67.6978 9.45444 68.5331 8.69503 70.1279 8.69503Z"
        fill="#222222"
      />
      <path
        d="M47.1938 17.732C47.1938 18.7951 46.8141 19.7064 46.1306 20.4658C45.4472 21.2252 44.384 21.529 43.3208 21.529C42.2577 21.529 41.3464 21.1493 40.6629 20.4658C39.9795 19.7064 39.6757 18.7951 39.6757 17.732C39.6757 16.6688 40.0554 15.7575 40.6629 15.074C41.3464 14.3146 42.2577 13.9349 43.3208 13.9349C44.384 13.9349 45.4472 14.3146 46.1306 15.074C46.8141 15.7575 47.1938 16.5929 47.1938 17.732ZM47.1179 12.6439C45.9788 11.6567 44.6118 11.2011 43.0171 11.2011C41.2704 11.2011 39.5238 11.8086 38.3847 13.0236C37.1696 14.2387 36.6381 15.8334 36.6381 17.656C36.6381 19.5545 37.2456 21.1493 38.3847 22.3643C39.5238 23.6553 41.1945 24.2629 43.0171 24.2629C44.6878 24.2629 45.8269 23.8072 47.1179 22.82V23.9591H49.9277V11.4289H47.1179V12.6439Z"
        fill="#222222"
      />
      <path
        d="M85.5445 11.0874C83.7219 11.0874 82.2031 11.8468 81.064 13.2897V11.3912H79.0136V28.7056H81.064V21.871C82.1272 23.2379 83.646 23.9973 85.4686 23.9973C87.2152 23.9973 88.734 23.3898 89.8731 22.1747C91.0122 20.8837 91.6198 19.3649 91.6198 17.6183C91.6198 15.7957 91.0122 14.2769 89.8731 13.0619C88.734 11.6949 87.2911 11.0874 85.5445 11.0874ZM85.3167 22.0988C84.0257 22.0988 82.9625 21.6432 82.2031 20.8078C81.4437 19.8965 80.9881 18.8334 80.9881 17.5424C80.9881 16.2514 81.3678 15.1882 82.2031 14.2769C82.9625 13.3656 84.0257 12.91 85.3167 12.91C86.6077 12.91 87.6708 13.3656 88.4302 14.2769C89.2656 15.1882 89.6453 16.2514 89.6453 17.5424C89.6453 18.8334 89.2656 19.8965 88.4302 20.8078C87.5949 21.6432 86.6077 22.0988 85.3167 22.0988ZM103.694 13.2137C102.555 11.8468 101.112 11.0874 99.2898 11.0874C97.5431 11.0874 96.0243 11.6949 94.8852 12.91C93.6702 14.125 93.1386 15.6438 93.1386 17.4664C93.1386 19.289 93.6702 20.8837 94.8852 22.1747C96.0243 23.3898 97.5431 23.9973 99.2898 23.9973C101.112 23.9973 102.555 23.3138 103.694 21.9469V23.6936H105.669V11.3912H103.694V13.2137ZM103.77 17.5424C103.77 18.8334 103.391 19.8965 102.555 20.8078C101.72 21.6432 100.733 22.0988 99.4416 22.0988C98.1507 22.0988 97.1634 21.6432 96.3281 20.8078C95.5687 19.8965 95.189 18.8334 95.189 17.5424C95.189 16.2514 95.5687 15.1882 96.3281 14.2769C97.1634 13.3656 98.1507 12.91 99.4416 12.91C100.733 12.91 101.796 13.3656 102.555 14.2769C103.391 15.1882 103.77 16.2514 103.77 17.5424ZM113.491 20.8078L109.542 11.3912H107.188L112.503 23.6176L112.048 24.8327C111.44 26.2755 110.681 27.0349 109.618 27.0349C109.238 27.0349 108.706 26.959 108.099 26.6552L107.871 28.4778C108.327 28.7816 108.934 28.9335 109.77 28.9335C111.592 28.9335 112.883 27.8703 113.946 25.2124L119.11 11.3912H116.832L113.491 20.8078Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1206 35.9201C13.6905 35.6922 11.4122 35.0088 9.28588 33.8697C5.26101 31.6674 2.14743 27.9463 0.704556 23.2379C-0.662381 18.5296 0.0210872 13.6694 2.22337 9.72044C5.10913 4.40457 10.6528 0.759409 16.5762 0H16.7281L18.2469 3.79705C18.171 3.79705 18.095 3.79705 18.095 3.79705C13.0829 4.10081 8.07083 7.06251 5.64072 11.543C3.89407 14.6566 3.43843 18.4536 4.5016 22.0988C5.56477 25.8199 8.07083 28.7057 11.1844 30.3764C12.8551 31.2877 14.6777 31.8192 16.5762 32.0471C20.753 32.4268 24.6259 31.0598 27.6636 28.6297C27.8155 28.4778 27.9673 28.4019 28.1192 28.25L29.638 32.0471C25.9169 34.8569 21.1327 36.3757 16.1206 35.9201ZM20.2214 8.88509H15.4371L9.134 24.453H13.2348L17.8672 12.3784L22.4996 24.453H26.6004L20.2214 8.88509Z"
        fill="#3899E2"
      />
    </svg>
  );
};

import { TypeSalomConditions } from '@constants';

export const getTypeSalomConditions = (type: string) => {
  switch (type) {
    case TypeSalomConditions.InMonths: {
      return 'мес';
    }
    case TypeSalomConditions.InDay: {
      return 'дн';
    }
    default: {
      return type;
    }
  }
};

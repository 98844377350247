export enum Statuses {
  Success = 'ok',
  Proggres = 'pending',
  Unsuccessful = 'unsuccessful',
  Canceled = 'сanceled',
  Failed = 'failed',
}

export enum StatusesRu {
  Success = 'Успешно',
  Proggres = 'Обрабатывается',
  Unsuccessful = 'Неуспешно',
  Canceled = 'Отменено',
}

import clsx from 'clsx';

import { getTypeSalomConditions } from '@utils';

import { IChipProps } from './types';

export const Chip = ({ condition, handleSelectValue, value }: IChipProps) => {
  return (
    <div
      key={condition.id}
      onClick={() => handleSelectValue(condition)}
      className={clsx(
        'flex w-full cursor-pointer flex-col items-center justify-center rounded-xl border py-2 transition-all duration-200 hover:border-primary sm:w-full',
        [condition.id === value ? 'border-primary' : 'border-grey-20'],
        [condition?.id === value ? 'bg-white' : 'bg-grey-20']
      )}
    >
      <div className="flex flex-col items-center justify-center">
        <p className={clsx([condition?.id === value && 'font-semibold'])}>
          {condition?.durationMax}
        </p>
        <span className="text-grey-500">
          {getTypeSalomConditions(condition.durationType)}
        </span>
      </div>
    </div>
  );
};

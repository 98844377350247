import { createContext } from 'react';

import { ICheckout } from '@entities/checkout';

export interface ICheckoutContext {
  checkout: ICheckout | undefined;
  handleSetCheckout: (checkout: ICheckout) => void;
}

const initialCheckoutContext: ICheckoutContext = {
  checkout: undefined,
  handleSetCheckout: () => null,
};

export const CheckoutContext = createContext<ICheckoutContext>(
  initialCheckoutContext
);

import { useState } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';

import { IChildTab, IHeaderTab } from '@types';

import { ITabsProps } from './types';

export const Tabs = ({
  headers,
  onChange,
  childes,
  className,
  selectedDefault = '',
}: ITabsProps) => {
  const [idChild, setIdChild] = useState<string>(
    selectedDefault || headers[0]?.id
  );

  const handleChange = (header: IHeaderTab): void => {
    setIdChild(header.id);
    onChange(header);
  };

  return (
    <div className={clsx('flex flex-col gap-1', className)}>
      <div className="flex w-full flex-wrap items-center gap-2 sm:gap-y-3 sm:px-5">
        {headers.map((header: IHeaderTab) => {
          const Icon = header.icon;
          return (
            <div
              className={clsx(
                'flex cursor-pointer items-center gap-2 text-nowrap rounded-full px-5 py-2 text-base transition-all duration-200 [&>*]:hover:text-white',
                [
                  idChild === header.id
                    ? 'bg-blue-black text-white'
                    : 'bg-grey-50 text-grey-500 hover:bg-blue-black hover:text-white',
                ]
              )}
              key={header.id}
              onClick={() => handleChange(header)}
            >
              <Icon
                className={clsx('', [
                  idChild === header.id ? 'text-white' : 'text-grey-500',
                ])}
              />{' '}
              <p className="text-nowrap">{header.title}</p>
            </div>
          );
        })}
      </div>
      <div className="flex items-center gap-1 py-3">
        {childes.map(
          (child: IChildTab) =>
            child.id === idChild && (
              <motion.div
                key={child.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                whileInView={{ opacity: 1 }}
                className="w-full"
              >
                {child.element}
              </motion.div>
            )
        )}
      </div>
    </div>
  );
};

import { PropsWithChildren, useEffect, useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { queryClient } from '@configs';
import { CheckoutContext } from '@contexts';
import { QueryClientProvider } from '@tanstack/react-query';

import { getToken, removeToken, setToken } from '@utils';
import { ICheckout } from '@entities/checkout';

export const Providers = ({ children }: PropsWithChildren) => {
  const width: number = window.innerWidth;
  const position = width >= 639 ? 'top-right' : 'top-center';
  const [checkout, setCheckout] = useState<ICheckout | undefined>(undefined);

  const handleSetCheckout = (checkout: ICheckout | undefined): void => {
    setCheckout(checkout);
  };

  useEffect(() => {
    const stk: any = document.querySelector('meta[name="stk"]');
    const route: any = document.querySelector('meta[name="route"]');
    const token: string = stk?.content;
    const url: string = route?.content;

    if (url && url !== '{{.Route}}' && url !== '/') {
      if (url.includes('tokenize')) {
        const parsedToken: string = token.split(':')[1];
        setToken(parsedToken);
      }
      window.location.href = url;
    } else if (token && token !== '{{.Stk}}') {
      const parsedToken: string = token.split(':')[1];
      setToken(parsedToken);
    }
  }, []);

  return (
    <CheckoutContext.Provider value={{ checkout, handleSetCheckout }}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          hideProgressBar
          position={position}
          closeButton={width >= 639}
        />

        {children}
      </QueryClientProvider>
    </CheckoutContext.Provider>
  );
};

import React, { PropsWithChildren } from 'react';

import { RouterProvider } from 'react-router-dom';

import { Providers } from './providers';
import { router } from './router';

export const App = ({ children }: PropsWithChildren) => {
  return (
    <Providers>
      {children}
      <RouterProvider router={router} />
    </Providers>
  );
};

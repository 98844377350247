import { SVGProps } from 'react';

export const DowloandIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 -0.000976562H9V10.5859L5 6.58591L3.58579 8.00012L9.29289 13.7072C9.68342 14.0978 10.3166 14.0978 10.7071 13.7072L16.4142 8.00012L15 6.58591L11 10.5859V-0.000976562Z"
        fill="currentColor"
      />
      <path
        d="M2 12.0001V17.0001C2 17.2653 2.10536 17.5197 2.29289 17.7072C2.48043 17.8948 2.73478 18.0001 3 18.0001H17C17.2652 18.0001 17.5196 17.8948 17.7071 17.7072C17.8946 17.5197 18 17.2653 18 17.0001V12.0001H20V17.0001C20 17.7958 19.6839 18.5588 19.1213 19.1214C18.5587 19.6841 17.7957 20.0001 17 20.0001H3C2.20435 20.0001 1.44129 19.6841 0.87868 19.1214C0.31607 18.5588 0 17.7958 0 17.0001V12.0001H2Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { ICondition } from '@entities/conditions-salom';
import { TypeSalomConditions } from '@constants';

export const sortConditions = (conditions: ICondition[]): ICondition[] => {
  if (!conditions.length) {
    return conditions;
  }

  const days: ICondition[] = conditions.filter(
    (condition) => condition.durationType === TypeSalomConditions.InDay
  );

  const months: ICondition[] = conditions.filter(
    (condition) => condition.durationType === TypeSalomConditions.InMonths
  );

  return [...days, ...months];
};

import { useEffect, useState } from 'react';

import { useFormik } from 'formik';

import { BASE_URL } from '@constants';
import { Button, Input, Autocomplete } from '@ui';

import { ITestParams } from './types';
import { useSendTestQuery } from './api';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require('crypto-js');

const callbackUrl = BASE_URL + '/test' || '';
const terminalKey = '524826';
const passWordTerminal =
  'dbc2e1a7f2326a8d306167a1f753bcd58dbf4b86b7e49746837d16276a1aac7b';
const amount = '1.00';

export const FormTest = () => {
  const [token, setToken] = useState('');
  const { mutate, isPending } = useSendTestQuery();

  function generateToken(
    key: string,
    orderId: string,
    amount: string,
    callbackUrl: string
  ) {
    if (values.gate === 'tokenization_km') {
      const tkn: string = key + values.phone + values.gate;
      const token = CryptoJS.HmacSHA256(tkn, passWordTerminal).toString(
        CryptoJS.enc.Hex
      );
      return token;
    }

    const tkn: string = key + orderId + amount + callbackUrl;
    const token = CryptoJS.HmacSHA256(tkn, passWordTerminal).toString(
      CryptoJS.enc.Hex
    );
    return token;
  }

  const { values, setFieldValue, handleSubmit } = useFormik<ITestParams>({
    initialValues: {
      email: 'test@mail.ru',
      phone: '+992917304745',
      callbackUrl,
      gate: '',
      amount,
      token,
      key: String(terminalKey),
      orderId: '',
      returnUrl: 'https://test',
      infoHash: '',
      info: 'Пополнение баланса',
      clientId: '12345',
      requestId: '',
    },
    onSubmit: (values) => mutate(values),
  });

  useEffect(() => {
    if (values.orderId || values.requestId) {
      const token = generateToken(
        values.key,
        values.orderId,
        values.amount,
        callbackUrl
      );
      setToken(token);
      setFieldValue('token', token);
    }
  }, [values.orderId, values.requestId]);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-8">
      <div className="flex gap-4 sm:flex-col">
        <div className="flex w-[50%] flex-col gap-4 sm:w-full">
          <Input
            label="Token"
            placeholder="Token"
            className="text-start"
            initialValue={values.token}
            onChange={(value) => setFieldValue('token', value)}
          />
          <Input
            label="Key"
            placeholder="Key"
            className="text-start"
            initialValue={values.key}
            onChange={(value) => setFieldValue('key', value)}
          />
          <Input
            label="Сallback url"
            className="text-start"
            placeholder="Сallback url"
            initialValue={values.callbackUrl}
            onChange={(value) => setFieldValue('callbackUrl', value)}
          />
          <Input
            label="Return url"
            className="text-start"
            placeholder="Return url"
            initialValue={values.returnUrl}
            onChange={(value) => setFieldValue('returnUrl', value)}
          />
          <Input
            label="Order id"
            className="text-start"
            placeholder="Order id"
            initialValue={values.orderId}
            onChange={(value) => setFieldValue('orderId', value)}
          />
          <Input
            label="Info"
            placeholder="Info"
            className="text-start"
            initialValue={values.info}
            onChange={(value) => setFieldValue('info', value)}
          />
          <Input
            label="Email"
            className="text-start"
            placeholder="Email"
            initialValue={values.email}
            onChange={(value) => setFieldValue('email', value)}
          />
        </div>
        <div className="flex w-[50%] flex-col gap-4 sm:w-full">
          <Input
            label="Phone"
            placeholder="Phone"
            className="text-start"
            initialValue={values.phone}
            onChange={(value) => setFieldValue('phone', value)}
          />
          <Input
            label="Info hash"
            className="text-start"
            placeholder="Info hash"
            initialValue={values.infoHash}
            onChange={(value) => setFieldValue('infoHash', value)}
          />
          <Input
            label="Amount"
            className="text-start"
            placeholder="Amount"
            initialValue={values.amount}
            onChange={(value) => setFieldValue('amount', value)}
          />
          <Autocomplete
            label="Gate"
            placeholder="Gate"
            defaultOption={{ value: values.gate, label: values.gate }}
            options={[
              { label: 'km', value: 'km' },
              { label: 'salom', value: 'salom' },
              { label: 'tokenization_km', value: 'tokenization_km' },
              {
                label: 'tokenization_operator',
                value: 'tokenization_operator',
              },
            ]}
            onSelect={(option) => setFieldValue('gate', option.value)}
          />
          <Input
            label="Client id"
            className="text-start"
            placeholder="Client id"
            initialValue={values.clientId}
            onChange={(value) => setFieldValue('clientId', value)}
          />
          <Input
            label="Request id"
            className="text-start"
            placeholder="Request id"
            initialValue={values.requestId}
            onChange={(value) => setFieldValue('requestId', value)}
          />
        </div>
      </div>
      <Button
        isLoading={isPending}
        type="submit"
        className="w-[30%] justify-center sm:w-full"
      >
        Отправть
      </Button>
    </form>
  );
};

import { PropsWithChildren } from 'react';

export const ContainerWrapper = ({ children }: PropsWithChildren) => {
  const width: number = window.innerWidth;

  if (width >= 639) {
    return <div className="container">{children}</div>;
  }

  return <div>{children}</div>;
};

import { instance } from '@configs';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { IResponse } from '@types';
import { redirect, toSnakeCaseFormat } from '@utils';

import { EndpointsConditionsQueryKeys } from './constants';
import {
  IApplicationParams,
  IApplicationResponse,
  ITrancheParams,
} from './types';

export const useApplicationQuery = () => {
  return useMutation({
    mutationFn: async (
      params: IApplicationParams
    ): Promise<IResponse<IApplicationResponse>> => {
      const { data } = await instance.post<IResponse<IApplicationResponse>>(
        EndpointsConditionsQueryKeys.TokenizationApplication,
        params
      );
      return data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};

export const useTrancheQuery = () => {
  return useMutation({
    mutationFn: async (params: ITrancheParams): Promise<IResponse<string>> => {
      const { data } = await instance.post<IResponse<string>>(
        EndpointsConditionsQueryKeys.TokenizationTranche,
        params,
        {
          transformRequest: [
            (data, headers) => {
              headers['Content-Type'] = 'application/json';
              return JSON.stringify(toSnakeCaseFormat(data));
            },
          ],
        }
      );
      return data;
    },
    onSuccess: (data) => {
      toast.success(data?.message);
      redirect(data?.payload || '');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};

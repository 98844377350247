import { createHashRouter } from 'react-router-dom';
import {
  ConditionsSalom,
  Home,
  NotFound,
  Statuses,
  StatusesTokenize,
  Test,
  Tokenization,
} from '@pages';

import { Layout } from '../layout';

export const router = createHashRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/test',
        element: <Test />,
      },
      {
        path: '/status',
        element: <Statuses />,
      },
      {
        path: '/status-tokenize',
        element: <StatusesTokenize />,
      },
      {
        path: '/tokenize',
        element: <Tokenization />,
      },
      {
        path: '/conditions-salom',
        element: <ConditionsSalom />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

import { useMutation, useQuery } from '@tanstack/react-query';
import { instance } from '@configs';
import {
  IContractNumber,
  IPaySalomParams,
  IResLogo,
  IResponseOtp,
  ISendOtpParams,
} from '@features/form-salom/types';
import { toast } from 'react-toastify';

import { IResponse } from '@types';
import { toSnakeCaseFormat } from '@utils';

import { EndpointsSalomQueryKeys, gateSalom } from './constants';

export const useContractNumberQuery = (isTokenization: boolean) => {
  return useMutation({
    mutationFn: async (
      contractNumber: number
    ): Promise<IResponse<IContractNumber>> => {
      const { data } = await instance.post(
        isTokenization
          ? EndpointsSalomQueryKeys.TokenizeCheck
          : EndpointsSalomQueryKeys.Conditions,
        {
          contractNumber,
        },
        {
          headers: {
            Gate: gateSalom,
          },
          transformRequest: [
            (data, headers) => {
              headers['Content-Type'] = 'application/json';
              return JSON.stringify(toSnakeCaseFormat(data));
            },
          ],
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data.code === 200) {
        toast.success(data.message);
      }
    },
  });
};

export const useSendOtpQuery = () => {
  return useMutation({
    mutationFn: async (
      params: ISendOtpParams
    ): Promise<IResponse<IResponseOtp>> => {
      const { data } = await instance.post<IResponse<IResponseOtp>>(
        EndpointsSalomQueryKeys.Otp,
        params,
        {
          transformRequest: [
            (data, headers) => {
              headers['Content-Type'] = 'application/json';
              return JSON.stringify(toSnakeCaseFormat(data));
            },
          ],
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data.code === 200) {
        toast.success(data.message);
      }
    },
  });
};

export const usePaySalom = (isTokenization: boolean) => {
  return useMutation({
    mutationFn: async (params: IPaySalomParams): Promise<IResponse<string>> => {
      const { data } = await instance.post<IResponse<string>>(
        isTokenization
          ? EndpointsSalomQueryKeys.TokenizationCommit
          : EndpointsSalomQueryKeys.Pay,
        isTokenization ? { otp: String(params.confirmationCode) } : params,
        {
          headers: {
            Gate: gateSalom,
          },
          transformRequest: [
            (data, headers) => {
              headers['Content-Type'] = 'application/json';
              return JSON.stringify(toSnakeCaseFormat(data));
            },
          ],
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data.code === 200) {
        toast.success(data.message);
      }
    },
  });
};

export const useGetLogoSalomQuery = (number: string) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await instance.get<IResponse<IResLogo>>(
        `${EndpointsSalomQueryKeys.GetLogo}/${number}`
      );
      return data;
    },
    queryKey: [number],
    enabled: number.length >= 5,
  });
};

import { SVGProps } from 'react';

export const SalomOutlineCardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 5.79086 1.79086 4 4 4H20C22.2091 4 24 5.79086 24 8V16C24 18.2091 22.2091 20 20 20H4C1.79086 20 0 18.2091 0 16V8ZM4 6C2.89543 6 2 6.89543 2 8V16C2 17.1046 2.89543 18 4 18H20C21.1046 18 22 17.1046 22 16V8C22 6.89543 21.1046 6 20 6H4ZM9.95701 10.2438C9.64793 10.0754 9.29988 9.99153 8.94801 10.0007C8.59615 10.0098 8.25292 10.1117 7.95302 10.2959C7.65311 10.4802 7.40715 10.7403 7.23997 11.0501C7.0728 11.3599 6.99033 11.7082 7.0009 12.0601C7.01147 12.4119 7.11471 12.7547 7.30019 13.0539C7.48566 13.353 7.7468 13.5979 8.05723 13.7639C8.36765 13.9298 8.71637 14.0108 9.06816 13.9988C9.41994 13.9868 9.76233 13.8822 10.0607 13.6955C10.5289 13.4026 11.146 13.5447 11.4389 14.0129C11.7318 14.4812 11.5897 15.0982 11.1215 15.3911C10.5247 15.7644 9.83988 15.9737 9.13631 15.9977C8.43274 16.0217 7.73531 15.8596 7.11445 15.5277C6.4936 15.1959 5.97132 14.706 5.60037 14.1077C5.22942 13.5094 5.02295 12.8238 5.0018 12.1201C4.98066 11.4165 5.1456 10.7197 5.47995 10.1002C5.8143 9.48069 6.30623 8.9604 6.90604 8.59187C7.50585 8.22335 8.19229 8.01965 8.89603 8.00135C9.59977 7.98305 10.2959 8.1508 10.914 8.48766C11.399 8.75193 11.5779 9.3593 11.3136 9.84425C11.0493 10.3292 10.442 10.5081 9.95701 10.2438ZM16 10C14.8954 10 14 10.8954 14 12C14 13.1046 14.8954 14 16 14C17.1046 14 18 13.1046 18 12C18 10.8954 17.1046 10 16 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

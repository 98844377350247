const key = 'token';

export const setToken = (token: string) => {
  return localStorage.setItem(key, token);
};

export const getToken = () => {
  return localStorage.getItem(key);
};

export const removeToken = () => {
  localStorage.setItem(key, '');
};

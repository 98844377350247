export enum EndpointsSalomQueryKeys {
  Conditions = '/salom/conditions',
  Otp = '/salom/application',
  Pay = '/salom/tranche',
  TokenizeCheck = '/tokenization/submit',
  TokenizationCommit = '/tokenization/commit',
  GetLogo = '/salom/logo',
}

export const gateSalom = 'salom';

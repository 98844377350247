import { Outlet } from 'react-router-dom';

import { Footer } from '@widgets/footer';
import { Navbar } from '@widgets/navbar';

export const Layout = () => {
  return (
    <div>
      <Navbar />
      <div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

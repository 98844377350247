import { useMutation } from '@tanstack/react-query';
import { instance } from '@configs';
import { toast } from 'react-toastify';

import { getContentInHtml } from '@utils';

import { ITestParams } from './types';

export const useSendTestQuery = () => {
  return useMutation({
    mutationFn: async (params: ITestParams) => {
      const formData: FormData = new FormData();
      formData.append('key', params.key);
      formData.append('callbackUrl', params.callbackUrl);
      formData.append('returnUrl', params.returnUrl);
      formData.append('orderId', params.orderId);
      formData.append('info', params.info);
      formData.append('email', params.email);
      formData.append('phone', params.phone);
      formData.append('infoHash', params.infoHash);
      formData.append('amount', params.amount);
      formData.append('gate', params.gate);
      formData.append('token', params.token);
      formData.append('requestId', params.requestId);
      formData.append('clientId', params.clientId);

      const { data } = await instance.post('', formData);

      return getContentInHtml(data);
    },
    onSuccess: (data: string[]) => {
      const [stk] = data;
      if (stk.length > 10) {
        toast.error(stk);
        // const token: string = stk.split(':')[1];
        // setToken(token);
        // window.location.href = '/';
      }
    },
  });
};

import { SVGProps } from 'react';

export const AlifPayBlackIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="121"
      height="36"
      viewBox="0 0 121 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.5473 6.49288H52.5856V23.9592H55.5473V6.49288ZM59.6481 10.0621C60.7112 10.0621 61.5466 9.30268 61.5466 8.16357C61.5466 7.1004 60.7872 6.26505 59.6481 6.26505C58.5849 6.26505 57.6736 7.02446 57.6736 8.16357C57.7496 9.22674 58.5849 10.0621 59.6481 10.0621ZM61.1669 11.505H58.1293V23.9592H61.1669V11.505ZM70.1279 8.69515C70.6595 8.69515 71.3429 8.84704 72.1023 9.22674L73.0136 6.87258C71.8745 6.34099 70.8114 6.03723 69.6722 6.03723C66.7106 6.03723 64.7361 7.78387 64.7361 10.8974V11.505H62.7616V14.011H64.7361V23.9592H67.6978V14.011H71.5708V11.505H67.6978V10.9734C67.6978 9.45456 68.5331 8.69515 70.1279 8.69515Z"
        fill="white"
      />
      <path
        d="M47.1938 17.7321C47.1938 18.7953 46.8141 19.7065 46.1306 20.4659C45.4472 21.2254 44.384 21.5291 43.3208 21.5291C42.2577 21.5291 41.3464 21.1494 40.6629 20.4659C39.9795 19.7065 39.6757 18.7953 39.6757 17.7321C39.6757 16.6689 40.0554 15.7576 40.6629 15.0742C41.3464 14.3148 42.2577 13.935 43.3208 13.935C44.384 13.935 45.4472 14.3148 46.1306 15.0742C46.8141 15.7576 47.1938 16.593 47.1938 17.7321ZM47.1179 12.6441C45.9788 11.6568 44.6118 11.2012 43.0171 11.2012C41.2704 11.2012 39.5238 11.8087 38.3847 13.0238C37.1696 14.2388 36.6381 15.8336 36.6381 17.6561C36.6381 19.5547 37.2456 21.1494 38.3847 22.3645C39.5238 23.6555 41.1945 24.263 43.0171 24.263C44.6878 24.263 45.8269 23.8073 47.1179 22.8201V23.9592H49.9277V11.429H47.1179V12.6441Z"
        fill="white"
      />
      <path
        d="M85.5446 11.0874C83.722 11.0874 82.2032 11.8468 81.0641 13.2897V11.3912H79.0137V28.7056H81.0641V21.871C82.1272 23.2379 83.6461 23.9973 85.4686 23.9973C87.2153 23.9973 88.7341 23.3898 89.8732 22.1747C91.0123 20.8837 91.6198 19.3649 91.6198 17.6183C91.6198 15.7957 91.0123 14.2769 89.8732 13.0619C88.7341 11.6949 87.2912 11.0874 85.5446 11.0874ZM85.3167 22.0988C84.0258 22.0988 82.9626 21.6432 82.2032 20.8078C81.4438 19.8965 80.9881 18.8334 80.9881 17.5424C80.9881 16.2514 81.3678 15.1882 82.2032 14.2769C82.9626 13.3656 84.0258 12.91 85.3167 12.91C86.6077 12.91 87.6709 13.3656 88.4303 14.2769C89.2657 15.1882 89.6454 16.2514 89.6454 17.5424C89.6454 18.8334 89.2657 19.8965 88.4303 20.8078C87.595 21.6432 86.6077 22.0988 85.3167 22.0988ZM103.694 13.2137C102.555 11.8468 101.112 11.0874 99.2898 11.0874C97.5432 11.0874 96.0244 11.6949 94.8853 12.91C93.6702 14.125 93.1386 15.6438 93.1386 17.4664C93.1386 19.289 93.6702 20.8837 94.8853 22.1747C96.0244 23.3898 97.5432 23.9973 99.2898 23.9973C101.112 23.9973 102.555 23.3138 103.694 21.9469V23.6936H105.669V11.3912H103.694V13.2137ZM103.77 17.5424C103.77 18.8334 103.391 19.8965 102.555 20.8078C101.72 21.6432 100.733 22.0988 99.4417 22.0988C98.1507 22.0988 97.1635 21.6432 96.3281 20.8078C95.5687 19.8965 95.189 18.8334 95.189 17.5424C95.189 16.2514 95.5687 15.1882 96.3281 14.2769C97.1635 13.3656 98.1507 12.91 99.4417 12.91C100.733 12.91 101.796 13.3656 102.555 14.2769C103.391 15.1882 103.77 16.2514 103.77 17.5424ZM113.491 20.8078L109.542 11.3912H107.188L112.503 23.6176L112.048 24.8327C111.44 26.2755 110.681 27.0349 109.618 27.0349C109.238 27.0349 108.706 26.959 108.099 26.6552L107.871 28.4778C108.327 28.7816 108.934 28.9335 109.77 28.9335C111.592 28.9335 112.883 27.8703 113.946 25.2124L119.11 11.3912H116.832L113.491 20.8078Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1206 35.9201C13.6905 35.6922 11.4122 35.0088 9.28588 33.8697C5.26101 31.6674 2.14743 27.9463 0.704556 23.2379C-0.662381 18.5296 0.0210872 13.6694 2.22337 9.72044C5.10913 4.40457 10.6528 0.759409 16.5762 0H16.7281L18.2469 3.79705C18.171 3.79705 18.095 3.79705 18.095 3.79705C13.0829 4.10081 8.07083 7.06251 5.64072 11.543C3.89407 14.6566 3.43843 18.4536 4.5016 22.0988C5.56477 25.8199 8.07083 28.7057 11.1844 30.3764C12.8551 31.2877 14.6777 31.8192 16.5762 32.0471C20.753 32.4268 24.6259 31.0598 27.6636 28.6297C27.8155 28.4778 27.9673 28.4019 28.1192 28.25L29.638 32.0471C25.9169 34.8569 21.1327 36.3757 16.1206 35.9201ZM20.2214 8.88509H15.4371L9.134 24.453H13.2348L17.8672 12.3784L22.4996 24.453H26.6004L20.2214 8.88509Z"
        fill="#3899E2"
      />
    </svg>
  );
};

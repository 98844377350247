import { SVGProps } from 'react';

export const MobileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.93321 3.05103L9.00102 3.04559C9.02485 3.57671 9.46299 4 10 4H14C14.537 4 14.9751 3.57671 14.999 3.04559L15.0668 3.05103C15.6273 3.09834 15.8339 3.17849 15.9359 3.23247C16.2902 3.42007 16.5799 3.70983 16.7675 4.06414C16.8215 4.16609 16.9017 4.37266 16.949 4.93321C16.9983 5.5183 17 6.28799 17 7.48V16.52C17 17.712 16.9983 18.4817 16.949 19.0668C16.9017 19.6273 16.8215 19.8339 16.7675 19.9359C16.5799 20.2902 16.2902 20.5799 15.9359 20.7675C15.8339 20.8215 15.6273 20.9017 15.0668 20.949C14.4817 20.9983 13.712 21 12.52 21H11.48C10.288 21 9.5183 20.9983 8.93321 20.949C8.37266 20.9017 8.16609 20.8215 8.06414 20.7675C7.70983 20.5799 7.42007 20.2902 7.23247 19.9359C7.17849 19.8339 7.09834 19.6273 7.05103 19.0668C7.00166 18.4817 7 17.712 7 16.52V7.48C7 6.28799 7.00166 5.5183 7.05103 4.93321C7.09834 4.37266 7.17849 4.16609 7.23247 4.06414C7.42007 3.70983 7.70983 3.42007 8.06414 3.23247C8.16609 3.17849 8.37266 3.09834 8.93321 3.05103ZM5.46494 3.12828C5 4.00639 5 5.16426 5 7.48V16.52C5 18.8357 5 19.9936 5.46494 20.8717C5.84014 21.5803 6.41965 22.1599 7.12828 22.5351C8.00639 23 9.16426 23 11.48 23H12.52C14.8357 23 15.9936 23 16.8717 22.5351C17.5803 22.1599 18.1599 21.5803 18.5351 20.8717C19 19.9936 19 18.8357 19 16.52V7.48C19 5.16426 19 4.00639 18.5351 3.12828C18.1599 2.41965 17.5803 1.84014 16.8717 1.46494C15.9936 1 14.8357 1 12.52 1H11.48C9.16426 1 8.00639 1 7.12828 1.46494C6.41965 1.84014 5.84014 2.41965 5.46494 3.12828ZM11.5 18C10.9477 18 10.5 18.4477 10.5 19C10.5 19.5523 10.9477 20 11.5 20H12.5C13.0523 20 13.5 19.5523 13.5 19C13.5 18.4477 13.0523 18 12.5 18H11.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

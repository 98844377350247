import { instance } from '@configs';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';

import { IResponse } from '@types';

import { IResLogo, ICheckPhoneParams, ISendOtpParams } from './types';
import {
  EndpointsPhonesQueryKeys,
  PhonesQueryKeys,
  tokenizationOperator,
} from './constants';

export const useCheckPhoneQuery = (isTokenization: boolean) => {
  return useMutation({
    mutationFn: async (
      params: ICheckPhoneParams
    ): Promise<IResponse<ICheckPhoneParams>> => {
      const { data } = await instance.post(
        isTokenization
          ? EndpointsPhonesQueryKeys.TokenizeCheck
          : EndpointsPhonesQueryKeys.Check,
        params,
        {
          headers: {
            Gate: tokenizationOperator,
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data.code === 200) {
        toast.success(data.message);
      }
    },
  });
};

export const useSendOtpQuery = (isTokenization: boolean) => {
  return useMutation({
    mutationFn: async (params: ISendOtpParams) => {
      const { data } = await instance.post(
        isTokenization
          ? EndpointsPhonesQueryKeys.TokenizationCommit
          : EndpointsPhonesQueryKeys.Pay,
        isTokenization ? { otp: params.otp } : params,
        {
          headers: {
            Gate: tokenizationOperator,
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data.code === 200) {
        toast.success(data.message);
      }
    },
  });
};

export const getLogoMobileQuery = (number: string) => {
  return useQuery({
    queryFn: async (): Promise<IResLogo | null> => {
      if (number.length >= 13) {
        const { data } = await instance.get<IResponse<IResLogo>>(
          `${EndpointsPhonesQueryKeys.Logo}/${number}`
        );
        return data.payload;
      }
      return null;
    },
    queryKey: [PhonesQueryKeys.Logo, number],
  });
};

import { Fragment } from 'react';

import {
  CreditCardIcon,
  MobileIcon,
  SalomOutlineCardIcon,
  WalletIcon,
} from '@icons';

import { TransactionTypes, TransactionTypesLabel } from '@constants';
import { FormMobi } from '@features/form-mobi';
import { FormCards } from '@features/form-cards';
import { FormSalom } from '@features/form-salom';
import { ICheckout } from '@entities/checkout';
import { FormPhones } from '@features/form-phones';

export const getTitleTransactionTypes = (
  type: string,
  isTokenization?: boolean
) => {
  switch (type) {
    case TransactionTypes.Wallet: {
      return TransactionTypesLabel.Wallet;
    }
    case TransactionTypes.KortiMilli: {
      return isTokenization
        ? TransactionTypesLabel.TokenizationTitleCard
        : TransactionTypesLabel.KortiMilli;
    }
    case TransactionTypes.Salom: {
      return TransactionTypesLabel.Salom;
    }
    case TransactionTypes.Operator: {
      return isTokenization
        ? TransactionTypesLabel.NumberOperator
        : TransactionTypesLabel.Operator;
    }
    default: {
      return '';
    }
  }
};

export const getIconTransactionTypes = (type: string) => {
  switch (type) {
    case TransactionTypes.Wallet: {
      return WalletIcon;
    }
    case TransactionTypes.KortiMilli: {
      return CreditCardIcon;
    }
    case TransactionTypes.Salom: {
      return SalomOutlineCardIcon;
    }
    case TransactionTypes.Operator: {
      return MobileIcon;
    }
    default: {
      return type;
    }
  }
};

export const getFormTransactionTypes = (
  type: string,
  checkout: ICheckout | undefined,
  isTokenization: boolean
) => {
  switch (type) {
    case TransactionTypes.Wallet: {
      return (
        <FormMobi
          isTokenization={isTokenization}
          typeTransaction={type}
          checkout={checkout}
        />
      );
    }
    case TransactionTypes.KortiMilli: {
      return (
        <FormCards
          isTokenization={isTokenization}
          type={type}
          checkout={checkout}
        />
      );
    }
    case TransactionTypes.Salom: {
      return <FormSalom isTokenization={isTokenization} checkout={checkout} />;
    }
    case TransactionTypes.Operator: {
      return (
        <FormPhones
          isTokenization={isTokenization}
          typeTransaction={type}
          checkout={checkout}
        />
      );
    }
    default: {
      return <Fragment />;
    }
  }
};

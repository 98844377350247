export enum EndpointsCardQueryKeys {
  Logo = '/card/logo',
  Pay = '/pay',
  Tokenization = '/tokenization/submit',
}

export enum CardQueryKeys {
  Logo = 'logo',
}

export const logos: string[] = ['union.svg'];

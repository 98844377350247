import {
  ICheckout,
  ITokenizationTypes,
  ITransactionType,
} from '@entities/checkout';
import { TransactionGroupTypes } from '@constants';
import { IChildTab, IHeaderTab } from '@types';

import {
  getFormTransactionTypes,
  getIconTransactionTypes,
  getTitleTransactionTypes,
} from './transaction-types';

export const parserTransactionsTypes = (
  checkout: ICheckout | undefined,
  isTokenization = false
): [headers: IHeaderTab[], childes: IChildTab[]] => {
  const headers: IHeaderTab[] = [];
  const childes: IChildTab[] = [];

  const transactionTypes: ITransactionType[] | ITokenizationTypes[] =
    isTokenization
      ? checkout?.tokenizationTypes || []
      : checkout?.transactionTypes || [];

  const cards: ITransactionType | ITokenizationTypes | undefined =
    transactionTypes.find(
      (transaction) => transaction.groupName === TransactionGroupTypes.Card
    );

  const wallet: ITransactionType | ITokenizationTypes | undefined =
    transactionTypes.find(
      (transaction) => transaction.groupName === TransactionGroupTypes.Wallet
    );

  const salom: ITransactionType | ITokenizationTypes | undefined =
    transactionTypes.find(
      (transaction) => transaction.groupName === TransactionGroupTypes.Salom
    );

  const operator: ITransactionType | ITokenizationTypes | undefined =
    transactionTypes.find(
      (transaction) => transaction.groupName === TransactionGroupTypes.Operator
    );

  [cards, wallet, salom, operator].forEach((transactionType) => {
    const header: IHeaderTab = {
      id: transactionType?.groupName || '',
      title: getTitleTransactionTypes(
        String(transactionType?.groupName),
        isTokenization
      ),
      icon: getIconTransactionTypes(String(transactionType?.groupName)),
    };

    const child: IChildTab = {
      id: transactionType?.groupName || '',
      element: getFormTransactionTypes(
        String(transactionType?.groupName),
        checkout,
        isTokenization
      ),
    };

    if (header.id && child.id) {
      headers.push(header);
      childes.push(child);
    }
  });

  return [headers, childes];
};

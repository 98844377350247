import { useState } from 'react';

import { useFormik } from 'formik';

import { Button, If, InfoPayment, Input, Offerta } from '@ui';
import { BASE_URL_LOGOS, TransactionGroupTypes } from '@constants';
import { ITransactionType } from '@entities/checkout';
import { checkFeeInfo, numberFormat } from '@utils';

import { parsePan } from './utils';
import { ICardParams, IFormCardsProps } from './types';
import { getLogoCardQuery, usePaymentCardQuery } from './api';
import { validationSchemaFormCards } from './schema';

export const FormCards = ({
  checkout,
  type,
  isTokenization = false,
}: IFormCardsProps) => {
  const { mutate, isPending } = usePaymentCardQuery(isTokenization);
  const [cardExp, setCardExp] = useState<string>('');
  const { values, setFieldValue, handleSubmit } = useFormik<ICardParams>({
    initialValues: {
      cardNumber: '',
      cardExpYear: '',
      holderName: '',
      cardExpMonth: '',
      cvv2: '',
    },
    validationSchema: validationSchemaFormCards,
    onSubmit: (values) => {
      mutate({ ...values, cardNumber: parsePan(values.cardNumber) });
    },
  });

  const { data: logoCard } = getLogoCardQuery(parsePan(values.cardNumber));

  const handleChangeExpCard = (value: string): void => {
    setCardExp(value);
    const [month, year] = value.split('/');
    setFieldValue('cardExpMonth', month);
    setFieldValue('cardExpYear', year);
  };

  const infoPay: ITransactionType | undefined =
    checkout?.transactionTypes?.find(
      (transactionType) => transactionType.groupName === type
    );

  const min: string = checkFeeInfo('min', String(infoPay?.feeInfo.min), ',');
  const max: string = checkFeeInfo('max', String(infoPay?.feeInfo.max));
  const infoStatic: string = checkFeeInfo(
    ' +',
    String(infoPay?.feeInfo.static),
    ','
  );

  const validateBtn = !(
    values.cardNumber &&
    values.cvv2 &&
    values.holderName &&
    values.cardExpMonth &&
    values.cardExpYear
  );

  const logos: ITransactionType[] | undefined =
    checkout?.transactionTypes?.filter(
      (checkout) => checkout.groupName === TransactionGroupTypes.Card
    );

  return (
    <div className="-ml-[150px] -mr-[150px] mt-5 rounded-xl bg-white p-[96px] sm:m-auto sm:rounded-none sm:p-7">
      <div className="mx-auto mb-10 w-[630px] sm:mb-4 sm:w-full">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-3 rounded-xl bg-grey-30 p-5">
            <Input
              maskChar=" "
              className="!bg-white"
              mask="9999 9999 9999 9999"
              label="Введите данные карты"
              placeholder="**** **** **** ****"
              initialValue={values.cardNumber}
              onChange={(value) => setFieldValue('cardNumber', value)}
              errorText={logoCard?.detailedInfo}
              isError={Boolean(logoCard?.detailedInfo)}
              endIcon={
                <div className="flex items-center gap-2">
                  <If
                    condition={Boolean(
                      logoCard?.issBank && logoCard?.issBank !== 'default.png'
                    )}
                  >
                    <img
                      alt="Logo"
                      src={`${BASE_URL_LOGOS}/logos/${logoCard?.issBank}`}
                      className="h-10 w-10 object-contain sm:h-7 sm:w-7"
                    />
                  </If>
                  <If
                    condition={Boolean(
                      logoCard?.brand && logoCard?.brand !== 'default.png'
                    )}
                  >
                    <img
                      alt="Logo"
                      src={`${BASE_URL_LOGOS}/logos/${logoCard?.brand}`}
                      className="h-10 w-10 object-contain sm:h-7 sm:w-7"
                    />
                  </If>
                </div>
              }
            />
            <div className="flex w-full items-center justify-between gap-2">
              <Input
                maskChar=" "
                className="!bg-white"
                mask="99/99"
                label="Срок действия"
                placeholder="12/24"
                initialValue={cardExp}
                onChange={handleChangeExpCard}
              />
              <Input
                label="CVV"
                maxLength={3}
                className="!bg-white"
                placeholder="* * *"
                autocomplete="one-time-code"
                type="password"
                mask="999"
                maskChar=""
                initialValue={values.cvv2}
                onChange={(value) => setFieldValue('cvv2', value)}
              />
            </div>
            <Input
              maxLength={58}
              translateEn={true}
              label="Имя владельца"
              className="!bg-white uppercase"
              placeholder="Name"
              initialValue={values.holderName}
              onChange={(value) => setFieldValue('holderName', value)}
            />
          </div>
          <If condition={Boolean(checkout && !isTokenization)}>
            <InfoPayment
              className="my-4"
              info={[
                {
                  label: 'Комиссия',
                  value: `${numberFormat(Number(infoPay?.totalFee))} c. ( ${infoPay?.feeInfo?.percent}%${infoStatic} ${min} ${max})`,
                },
                {
                  label: 'Сумма к зачислению',
                  value: `${numberFormat(Number(checkout?.amount))} с.`,
                },
                {
                  label: 'К оплате',
                  value: `${numberFormat(Number(infoPay?.totalAmount))} с.`,
                },
              ]}
            />
          </If>

          <Button
            type="submit"
            disabled={validateBtn}
            isLoading={isPending}
            className="mb-4 mt-5 w-full justify-center"
          >
            {isTokenization ? 'Привязать' : 'Оплатить'}
          </Button>
        </form>

        <Offerta isTokenization={isTokenization} />
        <div className="mt-14 flex items-center justify-center gap-3 gap-x-6 sm:mt-7 sm:overflow-x-scroll">
          {logos?.map((logo: ITransactionType) => (
            <img
              alt="Logo"
              key={logo.logoInfo}
              className="h-[40px] w-[60px] object-contain transition-transform hover:scale-[1.1]"
              src={`${BASE_URL_LOGOS}/logos/${logo.logoInfo}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

import { useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { ContainerWrapper, numberFormat, sortConditions } from '@utils';
import {
  Chip,
  ICondition,
  useGetConditionsQuery,
} from '@entities/conditions-salom';
import { Button, InfoPayment, Loader } from '@ui';

import { useApplicationQuery, useTrancheQuery } from './api';
import { IApplicationParams, ITrancheParams } from './types';

export const FormConditionsSalom = () => {
  const [searchParams] = useSearchParams();
  const id: string = searchParams.get('id') || '';

  const { data, isLoading } = useGetConditionsQuery(id);
  const { mutate: application, isPending } = useApplicationQuery();
  const { mutate: tranche, isPending: isPendingTranche } = useTrancheQuery();

  const sortedConditions: ICondition[] = sortConditions(data?.conditions || []);

  const [condition, setCondition] = useState<ICondition | null>(
    sortedConditions.length ? sortedConditions[0] : null
  );

  const handleApplication = (): void => {
    if (condition?.id) {
      const applicationParams: IApplicationParams = {
        id,
        conditionId: condition.id,
        token: data?.sessionToken || '',
      };
      application(applicationParams, {
        onSuccess: () => {
          const trancheParams: ITrancheParams = {
            ...applicationParams,
          };
          tranche(trancheParams);
        },
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ContainerWrapper>
      <div className="my-[120px] sm:my-[44px]">
        <div className="mx-auto flex w-[65%] flex-col gap-10 sm:w-full sm:gap-2 sm:overflow-hidden">
          <div className="mb-2 mt-10 sm:mt-0 sm:px-5">
            <p className="font-semibold">Выберите условия рассрочки</p>
          </div>
          <div className="-ml-[150px] -mr-[150px] mt-5 flex flex-col gap-4 rounded-xl bg-white p-24 sm:m-auto sm:w-full sm:rounded-none sm:px-6 sm:py-5">
            <div className="mb-10 grid grid-cols-6 items-center gap-2 sm:grid-cols-4">
              {sortedConditions.map((condition) => (
                <Chip
                  key={condition.id}
                  value={condition.id}
                  condition={condition}
                  handleSelectValue={(condition) => setCondition(condition)}
                />
              ))}
            </div>
            <InfoPayment
              className="mb-4"
              info={[
                {
                  label: 'Комиссия',
                  value: `${numberFormat(Number(condition?.totalAmount))} c. ( ${condition?.clientCommission}%)`,
                },
                {
                  label: 'Сумма к зачислению',
                  value: `${numberFormat(Number(condition?.amount))} с.`,
                },
                {
                  label: 'К оплате',
                  value: `${numberFormat(Number(condition?.totalAmount))} с.`,
                },
              ]}
            />
            <Button
              type="submit"
              onClick={handleApplication}
              className="w-full justify-center"
              isLoading={isPending || isPendingTranche}
            >
              Оформить
            </Button>
          </div>
        </div>
      </div>
    </ContainerWrapper>
  );
};
